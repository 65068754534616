import React from "react"

export default function CheapBlackLegionArmy() {
  return (
    <div>
      <header>
        <h1 className="site-title">
          <a href="/">The Dangron Hob-blog</a>
        </h1>
        <small>
          In the Grim Darkness of the 21st century there isn't really anything
          to do.
        </small>
      </header>

      <main>
        <h1>Cheap Black Legion Army</h1>

        <p>
          I'm a little late to the party on realising the sweet bargain that is
          the Chaos Space Marine Start Collecting box, I was playing around with
          Battlescribe and managed to make a 1500 point list that is pretty
          strong for just &pound;130ish.
        </p>

        {/* prettier-ignore */}
        <pre>{`
          ++ Battalion Detachment ++
          ++ (Chaos - Chaos Space Marines - Legion: Black Legion) ++
          
          + HQ +
          Abaddon the Despoiler [11 PL, 2CP, 220pts]: Warlord

          Master of Possession [5 PL, 95pts]
          
          + Troops +
          Chaos Space Marines [4 PL, 86pts]
          . Aspiring Champion: Chainaxe, Plasma pistol
          . 3x Marine w/ Bolt pistol, chainsword
          . Marine w/ heavy or special weapon: Plasma gun
          
          Chaos Space Marines [4 PL, 86pts]
          . Aspiring Champion: Chainaxe, Plasma pistol
          . 3x Marine w/ Bolt pistol, chainsword
          . Marine w/ heavy or special weapon: Plasma gun
          
          Chaos Space Marines [4 PL, 80pts]
          . Aspiring Champion: Bolt pistol, Boltgun
          . 3x Marine w/ Bolt pistol, Boltgun
          . Marine w/ heavy or special weapon: Autocannon
          
          + Elites +
          2 Greater Possessed [8 PL, 130pts]

          2 GreaterPossessed [8 PL, 130pts]
          
          + Heavy Support +
          
          3 Obliterators [10 PL, 315pts]

          1 Obliterator [10 PL, 105pts]
          
          ++ Patrol Detachment ++ ++
          (Chaos - Chaos Space Marines - Legion: Black Legion) ++

          + HQ +
          Master of Possession [5 PL, 95pts]
          
          + Troops +
          Chaos Space Marines [4 PL, 80pts]
          . Aspiring Champion: Bolt pistol, Boltgun
          . 3x Marine w/ Bolt pistol, Boltgun
          . Marine w/ heavy or special weapon: Autocannon
          
          + Heavy Support +
          Venomcrawler [7 PL, 130pts]

          Venomcrawler [7 PL, 130pts]
          
          ++ Total: [87 PL, 10 CP, 1,682pts] ++
        `}</pre>

        <p>
          On the field the army has great synergy with all of the{" "}
          <strong>&lt;Demon&gt;</strong>
          units getting buffs from the Masters of Posession and Greater
          Possessed buffed further still by Abaddon providing re-rolls to hit
          for all attacks. The units of Chaos Marines will grab objectives
          whilst everything else draws fire.
        </p>

        <p>
          This force utilises the magic triangle for cheap armies:
          <ul>
            <li>Elite army to ensure high points to pounds ratio</li>
            <li>Stacking good value Start Collecting Boxes</li>
            <li>Point dense beatstick characters</li>
          </ul>
        </p>

        <h3>Kits to buy:</h3>
        <ul>
          <li>2 x Start Collecting Chaos Space Marines</li>
          <li>1 x Abaddon the Despoiler</li>
        </ul>

        <p>
          I think a couple of{" "}
          <a
            target="_blank"
            href="https://www.goblingaming.co.uk/products/chaos-rhino-re-box?tap_a=18936-14583c&tap_s=268932-ebecc6"
          >
            Chaos Rhino's
          </a>{" "}
          to transport the melee marines and Greater Possessed might be worth it
          and then maybe deep strike in Abaddon with the Obliterators, depending
          on the match up.
        </p>

        <p>
          This army is incredible value, I'm seriously considering picking it up
          myself as I've always had a weakness to the dark gods. If you want to
          pick them up I have links to all the kits here:
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.goblingaming.co.uk/collections/warhammer-40k-chaos-space-marines/products/start-collecting-chaos-space-marines?tap_a=18936-14583c&tap_s=268932-ebecc6"
              >
                View Start Collecting Chaos Space Marines on Goblin Gaming
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.goblingaming.co.uk/products/abaddon-the-despoiler?tap_a=18936-14583c&tap_s=268932-ebecc6"
              >
                View Abaddon the Despoiler on Goblin Gaming
              </a>
            </li>
          </ul>
        </p>
      </main>
    </div>
  )
}
